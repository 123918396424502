<template>
  <div>
    <h1>Product Colors</h1>
  </div>
</template>

<script>
export default {
  name: "Product Colors",
};
</script>

<style></style>
